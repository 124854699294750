import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import parse from "html-react-parser";

class OverviewBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.cases.map((usecase, key) => (
          <Col lg={4} key={key}>
            <div
              className={
                usecase.isActive
                  ? "text-center overview-box hover-effect overview-active"
                  : "text-center overview-box hover-effect"
              }
            >
              <h1>{usecase.title}</h1>
              <br/>
              <img
                    src={usecase.icon}
                    alt={usecase.title}
                    className="img-fluid"
                    width={usecase.size}
                  />
             
              <p className="pt-5">{parse(usecase.subtitle)}</p>
              <p className="description">{parse(usecase.description)}</p>
             
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default OverviewBox;
