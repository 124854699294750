import React, { Component } from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";


class Finance extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-partners home-small"
          id="finance"
        >

          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="vertical-content">
                  <Col lg={12} className="text-center">
                  <h1 className="text-white">
                  Built for multi-cloud and multi-hardware support</h1>
                    </Col>
                </Row>
                <Row className="vertical-content2">
                <Col lg={3} className="text-center">
                  <img src="assets/images/partners/google.png" width="250" className="partners-img-white clients-img-medium " />
                    </Col>
                  <Col lg={3} className="text-center">
                  <img src="assets/images/partners/microsoft.png" width="250" className="partners-img-white clients-img-medium " />
                    </Col>
                    <Col lg={2} className="text-center">
                    <img src="assets/images/partners/ibm-logo.png" width="200" className="partners-img-white clients-img-small" />
                    </Col>
                    <Col lg={3} className="text-center">
                  <img src="assets/images/partners/confidential-containers.png" width="250" className="partners-img-white clients-img" />
                    </Col>
                    <Col lg={2} className="text-center">
                  <img src="assets/images/partners/amd.png" width="250" className="partners-img-white clients-img-small" />
                    </Col>
                    <Col lg={2} className="text-center">
                  <img src="assets/images/partners/intel.png" width="250" className="partners-img-white clients-img-small" />
                    </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
        
      </React.Fragment>
    );
  }
}

export default Finance;

