import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import CasesBox from "./articles-box";

class Cases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [
        {
          title: "Data Governance Act",
          desc: "<br/><br/><img width='100%' src='assets/images/articles/data-governance-act.png'/><br/><br/><br/><br/><a href='/assets/images/articles/data-governance-act.png' className='btn btn-primary'>Download visual</a>",
          featuring:"",
          icon: "pe-7s-map-2"
        },
        {
          title: "EU Data economy",
          desc: "<br/><br/><img width='100%' src='assets/images/articles/eu-data-strategy.png'/><br/><br/><br/><a href='/assets/images/articles/eu-data-strategy.png' className='btn btn-primary'>Download visual</a>",
          featuring:"",
          icon: "pe-7s-map-2"
        },
        {
          title: "Data collaboration<br/>for financial industry",
          desc: "<br/><br/><img width='100%' src='assets/images/demo/report.png'/><br/><br/><br/><a href='/contactreport' className='btn btn-primary'>Get report</a>",
          featuring:"",
          icon: "pe-7s-notebook"
        },/*
        {
          title: "Personal data <br/>& health",
          desc: "<iframe style='border-radius:12px' src='https://open.spotify.com/embed/episode/6RcQ74YdmAfswD69rHgbk2?utm_source=generator' width='100%' height='232' frameBorder='0' allowfullscreen='' allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'></iframe>",
          featuring:"<b>Giovanni Briganti</b> <br/> AI4Health Lead - AI4Belgium<br/><b>Alexander Olbrechts</b> <br/>Group Lead HealthTech - Agoria",
          icon: "pe-7s-volume"
        },
        {
          title: "Personal data, <br/>identity & consent",
          desc: "<iframe style='border-radius:12px' src='https://open.spotify.com/embed/episode/2EMLEtCOD0uSiCciUrFWmv?utm_source=generator' width='100%' height='232' frameBorder='0' allowfullscreen='' allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'></iframe>",
          featuring:"<b>Stephanie De Bruyne</b> <br/> Chief Executive officer - Itsme",
          icon: "pe-7s-volume"
        },
        {
          title: "Personal data <br/>& payments",
          desc: "<iframe style='border-radius:12px' src='https://open.spotify.com/embed/episode/1EKJBv5FZHG0cF0EOir9Fe?utm_source=generator' width='100%' height='232' frameBorder='0' allowfullscreen='' allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'></iframe>",
          featuring:"<b>Caroline Louveau</b> <br/> Chief privacy officer - Mastercard",
          icon: "pe-7s-volume"
        },*/
        {
          title: "Solid world - Media & personal data stores",
          desc: "<iframe src='https://player.vimeo.com/video/703101308?h=3a34705129#t=9m4s' width='640' height='100%' frameborder='0' allow='autoplay; fullscreen; picture-in-picture' allowfullscreen></iframe>",
          featuring:"<br/><br/><br/><b>Luk Overmeire</b> <br/> Technology Expert - Research and Innovation - VRT",
          icon: "pe-7s-film",
          link: "/retail",
          label: "Watch",
          iconbutton: "mdi mdi-play-circle-outline"
        },/*
        {
          title: "Media & <br/>personal data",
          desc: "<iframe  src='https://open.spotify.com/embed/episode/2KdunEcwXB6nGgbSA1KFTw?utm_source=generator' width='100%' height='232' frameBorder='0' allowfullscreen='' allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'></iframe>",
          featuring:"<b>Bill Thompson</b> <br/> Principal Research Engineer - BBC",
          icon: "pe-7s-volume"
        },
        {
          title: "PERSONAL DATA & CYBERSECURITY",
          desc: "<iframe  src='https://open.spotify.com/embed/episode/1OhsFRpHnlK9RoMVmdQiam?utm_source=generator' width='100%' height='232' frameBorder='0' allowfullscreen='' allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'></iframe>",
          featuring:"<b>Rosanna T.S. Kurrer</b> <br/> Co-Founder & Managing Director – CyberWayFinder",
          icon: "pe-7s-volume"
        },
        {
          title: "The Past, Present & Future of Personal Data",
          desc: "<iframe  src='https://open.spotify.com/embed/episode/5LVxzOifN2EVCicZdzBELl?utm_source=generator' width='100%' height='232' frameBorder='0' allowfullscreen='' allow='autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture'></iframe>",
          featuring:"<b>Stijn (Stan) Christiaens</b> <br/> Co-Founder & Chief Data Citizen – Collibra",
          icon: "pe-7s-volume"
        },*/
        {
          title: "Personal Data Processing & Privacy",
          desc: "<a href='https://event.on24.com/wcc/r/3400692/AA53A33C50084460E41F94E42CF53FB1?partnerref=website' target='_blank'><img width='100%' src='assets/images/articles/vdi-podcast1.png'/></a>",
          featuring:"<br/><b>Stijn (Stan) Christiaens</b> <br/> Co-Founder & Chief Data Citizen – Collibra<br/><b>Samuel Profumo</b> <br/>Chief Data Officer - RTBF<br/><b>Bart Vandekerckhove</b> <br/>Co-Founder - Volta",
          icon: "pe-7s-film"
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section pt-5" id="pricing">
          <Container>
            
            <Row className="mt-5">
              {/* cases box */}
              <CasesBox cases={this.state.cases} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Cases;
