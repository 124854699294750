import React, { Component } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./sectiondemo";
import Form from "../../components/Contact/demo-form";
import Footer from "../../components/Footer/footer";

class Index extends Component {
  
  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage />

        {/* section */}
        <Section />

        {/* About */}
        <Form />


        {/* footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index;
