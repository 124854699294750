import React, { Component } from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";


class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-usecases-by-industry home-half"
          id="solution"
        >
          <div className="bg-overlay-light"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="vertical-content">
                  <Col lg={12} className="text-white text-left ">
                  <p className="pt-3 home-desc-2 home-subtitle-width-100">
                    Datavillage use cases - by industry</p>
                    <h1 className="home-title3">
                    Provide and access more data confidentially 
                    </h1>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
        
      </React.Fragment>
    );
  }
}

export default Section;

