import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
//Import Section Title
import SectionTitle from "../common/section-title";

class AntiMoneyLaundering extends Component {
 
  render() {
    return (
      <React.Fragment>
        <section className="section">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Collaborative Transaction Monitoring"
              />
        
            <Row className="mt-3">

            <Col lg={6} >
              <div className="advantage-box-2  hover-effect">
               <p className="text-center  text-uppercase f-14">Create and distribute</p>
                <h2 className="text-center">Best-performing AI model</h2>
                <br/>
                <ul className="list-unstyled text-left ">
                      <li className="f-18">
                            <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Pre-train</b> your AI model based on your own data
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Create confidential AI</b> collaboration on Datavillage
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Publish your AI model</b> while keeping it confidential
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Connect encrypted labels</b> &amp; features from participants
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;Connect with your <b>transactions data</b> (real or synthetic) 
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Re-train your AI model</b> on encrypted data
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;Monitor performance &amp; <b>get best-performing AI model</b>
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Distribute your AI model </b> on Datavillage platform  
                        </li>
                    </ul>
                    <br/>
                    <p className="text-center"><Link
                      to="/contactdemo">
                          <Button
                            color="none"
                            type="button"
                            className="btn  btn-primary">
                            Talk to an expert
                          </Button></Link></p>
                  
              </div>
             </Col>
             <Col lg={6} ><br/>
             <img
                    src="assets/images/solutions/aml-video-1.gif"
                    alt="collaborative anti-money laundering"
                    width="100%"
                  />

             </Col>
            </Row>
            <Row className="mt-5">

            <Col lg={6} >
            <img
                    src="assets/images/solutions/aml-video-2.gif"
                    alt="collaborative anti-money laundering"
                    width="100%"
                  />

             </Col>

             <Col lg={6} >
              <div className="advantage-box-3  hover-effect">
                <p className="text-center  text-uppercase f-14">Use and fine tune </p>
                <h2 className="text-center">Specialised AI detection models</h2>
                <br/>
                
                <ul className="list-unstyled text-left ">
                      <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Create confidential AI</b> collaboration on Datavillage
                        </li>
                      <li className="f-18">
                            <i className="mdi mdi-check"></i>&nbsp;&nbsp;Select the <b>best AI detection model</b> for your need
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Invite AI model provider</b> to the collaboration
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Connect your encrypted</b> labels, features and <b>data</b>
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Fine tune AI model</b> to your needs
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Run</b> best-performing <b>transaction monitoring model</b> 
                        </li>
                    </ul>
                    <br/>
                    <p className="text-center"><Link
                      to="/contactdemo">
                          <Button
                            color="none"
                            type="button"
                            className="btn  btn-primary-dark">
                            Talk to an expert
                          </Button></Link></p>
               
              
              </div>
             </Col>
            </Row>
            <br/><br/>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AntiMoneyLaundering;