import React, { Component } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import ProductOverview from "../../components/Product/overview";
import ProductDetail from "../../components/Product/product-detail";
import ProductCapabilities from "../../components/Product/product-capabilities";
import Partners from "../../components/Partners/partners";
import ProductBenefits from "../../components/Product/product-benefits";
import Footer from "../../components/Footer/footer";

class Index extends Component {
  
  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage />

        {/* section */}
        <Section />
        
         {/* productOverview */}
         <ProductOverview />

        {/* Product detail */}
        <ProductDetail />

        {/* Product benefits */}
        <ProductBenefits />

        {/* Product capabilities */}
        < ProductCapabilities/>
        
        {/* Partners*/}
        < Partners/>

        {/* footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index;
