import React, { Component } from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";


class Section extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-home home-home"
          id="platform"
        >
          <div className="bg-overlay"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="vertical-content">
                  <Col lg={8} className="text-white text-left">
                    <h1 className="home-title home-subtitle-width-100">
                    <font style={{color:'aqua'}}>Solve challenges that can't be solved in your own data silo ! </font>
                      
                    </h1>
                    
                    <p className="home-subtitle-width-90">
                    Datavillage revolutionizes Data &amp; AI collaboration with a unique approach combining simplicity and security.
                    </p>
                    <br/>
                    <Link
                      to="/contact">
                          <Button
                            color="none"
                            type="button"
                            className="btn btn-primary">
                            Get in touch
                          </Button></Link>
                  </Col>
                  <Col lg={4}>
                    <img src="assets/images/illustration-home.png" width="90%" className="section-img"/>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>


        
      </React.Fragment>
    );
  }
}

export default Section;



