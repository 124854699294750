import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import JourneysBox from "./journeys-box";

class Journeys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [
        {
          title: "Platform & technology",
          desc: "Get more information about <br/>trusted data collaborations.",
          icon: "pe-7s-vector",
          link: "/platform"
        },
        {
          title: "Demo video",
          desc: "Check out to integrate <br/>privacy enhancing technologies.",
          icon: "pe-7s-video",
          link: "/technology"
        },
        
        {
          title: "Guides",
          desc: "Find out how you can benefit from trusted data collaborations.",
          icon: "pe-7s-copy-file",
          link: "/contactdemo"
        }
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section pt-6" id="journeys">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Share insights, not data"
              />
            <Row>
              {/* cases box */}
              <JourneysBox cases={this.state.cases} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Journeys;
