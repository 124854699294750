import React, { Component } from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";


class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-anti-money-laundering home-home"
          id="solution"
        >
          <div className="bg-overlay-light"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="vertical-content">
                  <Col lg={12} className="text-white text-left">
                    <h1 className="home-title home-subtitle-width-100">
                   Collaborative Transaction Monitoring
                    </h1>
                    <p className="home-subtitle-width-90">
                    Create and refine the best-performing AI detection models by joining forces while maintaining the privacy of sensitive data.</p>
                    <br/>
                    <Link
                      to="/contactdemo">
                          <Button
                            color="none"
                            type="button"
                            className="btn btn-primary">
                            Request a demo
                          </Button></Link>
                  </Col>
                  
                </Row>
              </Container>
            </div>
          </div>
        </section>
        
      </React.Fragment>
    );
  }
}

export default Section;

