import React, { Component } from "react";
import { Col } from "reactstrap";
import parse from "html-react-parser";

class ProductBenefitsBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.cases.map((usecase, key) => (
          <Col lg={3} key={key}>
            <div
              className="text-center"
            >
             
                   
              <h1 className="f-50 text-white"><i className={usecase.icon} ></i></h1>
              <h2 className="pt-3 text-white">{usecase.title} </h2>
              <p className="pt-1 text-muted text-white">{parse(usecase.desc)}</p>

              
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default ProductBenefitsBox;
