import React, { Component } from "react";
import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Article from "../../components/News/Articles/article5";
import Footer from "../../components/Footer/footer";


class Index extends Component {
 
  render() {

   
    
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage />

        {/* section */}
        <Section />

        {/* About */}
        <Article />

        {/* footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index;
