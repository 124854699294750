import React, { Component } from "react";
import { Container, Row,Col } from "reactstrap";

//Import Section Title
import SectionTitle from "../../common/section-title";


class Article extends Component {
  constructor(props) {
    super(props);
    
  }

  render() {
    return (
      <React.Fragment>
        <section className="section">
        <Container>
          <SectionTitle className="section-title text-center "
              title="Datavillage and Athumi: Shaping the Future of Data Collaboration"
              />
          <Row className="vertical-content ">
              <Col lg={12}>
              <div><p >
              <center><img src="/assets/images/articles/article5.png" className="blog-picture"></img></center>
              <br/><br/><br/>
              <b>Datavillage</b> is thrilled to announce a partnership with <b>Athumi</b>, a collaboration set to revolutionise the realm of data ecosystems through our shared commitment to secure and trusted data collaboration. <br/>This alliance brings together <b>Athumi</b>'s expertise in fostering new data-driven and open ecosystems and cross-sectoral innovations with <b>Datavillage</b>'s state-of-the-art <b>Platform™</b>.

              </p>
              </div>

              <p className="mt-4">
              <b>Athumi</b>, with its roots as the first European data utility company, has established itself as a leader in the creation of data sharing ecosystems through the open collaboration approach. <br/><b>Athumi</b> focuses on maintaining transparency and control for data owners and controllers through the development of interoperable, secure data spaces, blending datatech and legaltech.
  </p>

              <p className="mt-4">
              This complements <b>Datavillage</b>'s approach to accessing and processing sensitive data, ensuring end-to-end data encryption and algorithm integrity. <br/><b>Datavillage</b>'s Platform™ stands as a beacon of innovation, enabling organisations to combine sensitive, proprietary, or personal data in fully confidential environments. 
 </p>


              <p className="mt-4">
              <b>Datavillage</b>’s platform aligns perfectly with <b>Athumi</b>'s initiatives in developing B2C, B2B and G2B services through its Solid-based Personal Data Storage Platform. <br/>Together, we are uniquely positioned to offer comprehensive solutions for data collaboration, from government and business sectors to individual consumers. <br/>Our partnership promises to unlock new business value, generating insights and training AI models in a secure, privacy-preserving manner. <br/>The alignment of <b>Athumi</b>'s government-supported data sharing solutions with <b>Datavillage</b>'s secure AI application and transparent data governance model opens up a world of possibilities for ethical, efficient, and effective data utilisation.
 </p>

              <p className="mt-4">
              In this era of data-driven decision-making, our collaboration is more than just a partnership; it's a commitment to building a more connected, transparent, and innovative future. <br/><br/><b>Join us as we lead the charge in transforming data collaboration, ensuring it benefits all sectors of society while upholding the highest standards of security and privacy.</b>
 </p>
              </Col>
            </Row>
            <br/><br/><br/>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Article;
