import React, { Component } from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";


class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-finance home-home"
          id="solution"
        >
          <div className="bg-overlay-light"></div>
          <div className="display-table">
            <div className="display-table-cell">
            <Container>
                <Row className="vertical-content">
                  <Col lg={12} className="text-white text-left">
                    <h1 className="home-title home-subtitle-width-100">
                    Brand assets 
                    </h1>
                    <p className="home-subtitle-width-90">
                    Discover our brand resources and read up on the guidelines on using them.
                   </p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
        
      </React.Fragment>
    );
  }
}

export default Section;

