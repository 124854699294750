import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import parse from "html-react-parser";

class CasesBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.cases.map((usecase, key) => (
          <Col lg={6} key={key}>
            <div
              className={
                usecase.isActive
                  ? "pricing-box hover-effect price-active"
                  : "pricing-box hover-effect"
              }
            >
              <h1>{usecase.icon}</h1>
              <p>{parse(usecase.desc)}</p>
              <p className="f-15">{parse(usecase.provider)}</p>
             
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default CasesBox;
