import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import parse from "html-react-parser";

class StandardsBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.cases.map((usecase, key) => (
          <Col lg={4} key={key}>
            <div
              className="text-center"
            >
             
                   
              <h1 className="f-50"><i className={usecase.icon} ></i></h1>
              <h4 className="pt-3 text-uppercase">{usecase.title} </h4>
              <p className="pt-3 text-muted">{parse(usecase.desc)}</p>
              
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default StandardsBox;
