import React, { Component } from "react";

import Section from "./section";

class Index extends Component {
  
  render() {
    return (
      <React.Fragment>

        {/* section */}
        <Section />

      </React.Fragment>
    );
  }
}

export default Index;
