import React, { Component } from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";


class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="display-table-cell">
              <Container>
                <Row className="vertical-content">
                  <Col lg={12} className="text-left ">
                  <h1>Privacy Policy for Datavillage</h1>
                  <h4>Effective Date: 01/01/2021</h4>

                  <br/><br/>
                  <h4>Introduction</h4>
                  <p className="f-14">
                    At Datavillage, we are committed to protecting the privacy and security of our customers, employees, and other individuals whose personal data we process. This privacy policy explains how we collect, use, and disclose personal data in the course of our business activities, and how we ensure that such personal data is processed in accordance with applicable data protection laws.
                  </p>
                  <h4>Data Controller</h4>
                  <p className="f-14">
                    Datavillage is the data controller responsible for the personal data we process. Our contact information is:
                    Datavillage<br/>
                    Sint Michielswarande 30b<br/>
                    1040 Brussels<br/>
                    Belgium<br/>
                    Email: contact@datavillage.com
                  </p>
                  <h4>What Personal Data We Collect and Why</h4>
                  <p className="f-14">
                    
                    We collect personal data from you in the following circumstances:<br/>
                    <ul>
                      <li>When you visit our website, we do not collect any information.</li>
                      <li>When you register for an account with us, we may collect your name, email address, and other contact information to communicate with you about your account and our services.</li>
                      <li>When you use our services, we may collect personal data such as your name, contact information, and payment information in order to provide our services to you.</li>
                    </ul>
                    We process personal data for the following purposes:
                    <ul>
                      <li>To provide and improve our services.</li>
                      <li>To communicate with you about our services.</li>
                      <li>To comply with legal obligations.</li>
                      <li>We process personal data based on the following legal bases:</li>
                    </ul>
                  </p>
                  <h4>Your consent.</h4>
                  <p className="f-14">
                      The performance of a contract with you.
                        Compliance with legal obligations.
                        Our legitimate interests, including improving our services, communicating with you about our services, and protecting our legal rights.
                        How We Protect Personal Data

                        We take appropriate technical and organizational measures to protect personal data against accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access. We regularly review and update our security measures to ensure they remain effective.
                  </p>
                    
                  <h4>Your Rights</h4>
                  <p className="f-14">
                  You have the following rights with respect to your personal data:
                  <ul>
                    <li>The right to access your personal data.</li>
                    <li>The right to rectify your personal data.</li>
                    <li>The right to erasure of your personal data.</li>
                    <li>The right to restrict processing of your personal data.</li>
                    <li>The right to object to processing of your personal data.</li>
                    <li>The right to data portability.</li>
                  </ul>
                  To exercise your rights, please contact us at the address provided above.
                  </p>
                  <h4>Disclosure of Personal Data</h4>
                  <p className="f-14">
                  We may disclose personal data to third parties in the following circumstances:
                  <ul>
                    <li>To service providers who assist us in providing our services.</li>
                    <li>To comply with legal obligations.</li>
                    <li>To protect our legal rights.</li>
                  </ul>
                  We ensure that any third parties to whom we disclose personal data are contractually obligated to protect the privacy and security of personal data in accordance with applicable data protection laws.
                  </p>
                  <h4>International Transfers of Personal Data</h4>
                  <p className="f-14">
                  We may transfer personal data to countries outside of the European Economic Area (EEA) in the following circumstances:
                  <ul>
                    <li>To service providers who assist us in providing our services.</li>
                    <li>To comply with legal obligations.</li>
                  </ul>
                    We ensure that any international transfers of personal data are made in accordance with applicable data protection laws, including through the use of appropriate safeguards such as standard contractual clauses.
                  </p>
                  <h4> Data Retention</h4>
                  <p className="f-14">
                    We retain personal data for as long as necessary to provide our services and for other legitimate purposes, such as complying with legal obligations and resolving disputes.
                  </p>
                  <h4>Changes to This Privacy Policy</h4>
                  <p className="f-14"> 
                    We may update this privacy policy from time to time by posting a new version on our website. You should check this page occasionally to ensure you are familiar with any changes.
                  </p>
                  <h4>Contact Us</h4>
                  <p className="f-14">
                    If you have any questions about this privacy policy or our processing of personal data, please contact us at the address provided above.
                  </p>
                  </Col>
                </Row>
              </Container>
            </div>
      </React.Fragment>
    );
  }
}

export default Section;



