import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form } from "reactstrap";

class Footer extends Component {
  state = {
    linksPlatform: [
      {
        title: "Platform",
        child: [
          { link: "/platform", title: "Overview" }
        ],
      }
    ],
    linksSolutions: [
      {
        title: "Solutions",
        child: [
          { link: "/collaborative-transaction-monitoring", title: "Transaction monitoring" },
          { link: "/collaborative-fraud-scoring", title: "Fraud Scoring" }
        ],
      }
    ],
    linksResources: [
      {
        title: "Resources",
        child: [
          { link: "/blog", title: "Blog" },
          //{ link: "/news", title: "News" },
        ],
      }
    ],
    linksCompany: [
      {
        title: "Company",
        child: [
          { link: "/company", title: "Team" },
          { link: "/brand-assets", title: "Brand Assets" },
        ],
      }
    ],
  };
  render() {
    return (
      <React.Fragment>
        <footer className="contact-social footer">
          <Container>
            <Row>
              {this.state.linksPlatform.map((fLink, key) => (
                <Col key={key} lg={2} className="mt-4">
                  <h4>{fLink.title}</h4>
                  <div className="text-muted mt-4">
                    <ul className="list-unstyled footer-list">
                      {fLink.child.map((fLinkChild, key) => (
                        <li key={key}>
                          <Link to={fLinkChild.link}>{fLinkChild.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}
              {this.state.linksSolutions.map((fLink, key) => (
                <Col key={key} lg={2} className="mt-4">
                  <h4>{fLink.title}</h4>
                  <div className="text-muted mt-4">
                    <ul className="list-unstyled footer-list">
                      {fLink.child.map((fLinkChild, key) => (
                        <li key={key}>
                          <Link to={fLinkChild.link}>{fLinkChild.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}
              {this.state.linksResources.map((fLink, key) => (
                <Col key={key} lg={2} className="mt-4">
                  <h4>{fLink.title}</h4>
                  <div className="text-muted mt-4">
                    <ul className="list-unstyled footer-list">
                      {fLink.child.map((fLinkChild, key) => (
                        <li key={key}>
                          <Link to={fLinkChild.link}>{fLinkChild.title}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              ))}
              {this.state.linksCompany.map((fLink, key) => (
                <Col key={key} lg={2} className="mt-4">
                  <h4>{fLink.title}</h4>
                  <div className="text-muted mt-4">
                    <ul className="list-unstyled footer-list">
                      {fLink.child.map((fLinkChild, key) => (
                        <li key={key}>
                          <Link to={fLinkChild.link}>{fLinkChild.title}</Link>
                        </li>
                      ))}
                      </ul>
                      <ul className="list-inline social mt-4">
                      <li className="list-inline-item">
                        <Link to="linkedin" target="_blank" className="social-icon">
                          <i className="mdi mdi-linkedin"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to="github" target="_blank" className="social-icon">
                          <i className="mdi mdi-github-face"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              ))}
              <Col lg={4} className="mt-4">
              <h4>Contact</h4>
              <div className="text-muted mt-4">
                <ul className="list-unstyled footer-list">
                    <li>
                      <i className="pe-7s-note"></i>&nbsp; <a href="/contact">Get in touch</a>
                    </li>
                    <li>
                      <i className="pe-7s-mail-open"></i>&nbsp; <a href="mailto:contact@datavillage.me">contact@datavillage.me</a>
                    </li>
                    <li>
                    <i className="pe-7s-home"></i>&nbsp; <a href="https://maps.app.goo.gl/Py3ms9R9VGkDWSuZ6" target="_blank">Sluisstraat 79, 3000 Leuven</a>
                    </li>
                    <li>
                    &nbsp;&nbsp;&nbsp;&nbsp;<a href="https://maps.app.goo.gl/rz4s2yWArpqtr35G7" target="_blank">Square des martyrs 1, 6000 Charleroi</a>
                    </li>
                </ul>
              </div>
              </Col>
            </Row>
            <Row>
            <Col lg={12} ><hr/></Col>
            </Row>
            <Row>
              <Col lg={5}> 
              <div className="terms">
              Cookieless website, no user tracking here !
                </div>
                </Col>
              <Col lg={5}>
              <div className="terms"> <center><a href="/privacy" target="_blank" >Privacy policy</a></center>
              
              </div>
              </Col>
              <Col lg={2}>
              <div className="terms">
              Copyright &copy;&nbsp;{new Date().getFullYear()} Datavillage
             
                </div>
                </Col>
             </Row>
          </Container>
        </footer>
        {/* color switcher */}
      </React.Fragment>
    );
  }
}

export default Footer;
