import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
//Import Section Title
import SectionTitle from "../common/section-title";
import { Link } from "react-router-dom";


class UseCaseByObjectiveHeader extends Component {
  
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="media-header">
          <Container>
          <SectionTitle
              title="Financial institutions must meet AML obligations but can only detect 1% of suspicious transactions."
              />
             
              <Row className=" text-center ">
              <Col lg={6}>

              <div className="advantage-box-3  hover-effect">
                <h2>Relying on your own data is no longer enough ! <br/><br/>Use data and fine-tune AI collectively to better mitigate financial crime.</h2>
                <br/>
                <Link
                      to="/contactdemo">
                          <Button
                            color="none"
                            type="button"
                            className="btn  btn-primary-dark">
                            Request a demo
                          </Button></Link>
                          <br/><br/>
              </div>
              </Col>
              <Col lg={6}>
             
              <img
                    src="assets/images/solutions/anti-money-laundering.png"
                    alt="collaborative anti-money laundering"
                    width="50%"
                  />

              </Col>
           
              </Row>

               
              <br/>
             
             
          
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default UseCaseByObjectiveHeader;