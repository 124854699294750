import React, { Component } from "react";
import { Container, Row,Col } from "reactstrap";

//Import Section Title
import SectionTitle from "../../common/section-title";


class Article extends Component {
  constructor(props) {
    super(props);
    
  }

  render() {
    return (
      <React.Fragment>
        <section className="section">
        <Container>
          <SectionTitle className="section-title text-center "
              title="Revolutionizing Confidential Data Collaboration: Our new Partnership with VMware"
              />
          <Row className="vertical-content ">
              <Col lg={7}>
                
                <h3>
                The realm of multi-cloud confidential computing
                  </h3>
                  <p className="mt-4">
                  We are thrilled to announce a groundbreaking strategic partnership with VMware, a collaboration that underscores our unwavering commitment to pushing the boundaries of cloud technology. 
                  <br/>This alliance marks a pivotal moment in our journey, as we embark on a mission to redefine the standards of confidential data collaboration and drive innovation in the industry.
                  <br/><br/>At the heart of this partnership lies the utilization of the <a href="https://github.com/vmware-research/certifier-framework-for-confidential-computing" target="_blank"><u>"certifier framework"</u></a> within the realm of multi-cloud confidential computing. The certifier framework represents a significant leap forward in our shared vision for the future, offering a unique and revolutionary approach to distributing attestation mechanisms across the various participants involved in confidential data collaboration.
                  <br/><br/>Our primary objective in integrating this framework is to elevate the security, transparency, and efficiency of data sharing activities. This enhancement ensures that all stakeholders can operate with the utmost confidence in the integrity of the shared data, opening up new horizons for collaboration and trust in the cloud.
                  </p>
              </Col>
              <Col lg={5}>
                <div className="features-img features-right text-right">
                <br/><br/><br/><br/>
                  <img
                    src="/assets/images/articles/article1-1.png"
                    className="product-picture"
                  />
                </div>
              </Col>
            </Row>
            <Row className="vertical-content">
              
              <Col lg={12}>
             
                <h3>
                    What does this partnership mean in practical terms?
                  </h3>
                  <p className="mt-4">
                    <ol>
                      <li>
                      <b>Manage Confidential Collaboration Spaces Anywhere</b><br/>
                        With this partnership, you gain the freedom to manage your confidential collaboration spaces wherever you choose. Whether you prefer a centralized authority or a distributed approach, you no longer need to rely solely on the cloud provider as the ultimate authority over your data. This newfound flexibility empowers you to tailor your data collaboration strategy to your specific needs.
                        </li><li className="mt-3">
                        <b>Choose Your Trusted Root Authority</b><br/>

                        In this exciting collaboration, you have the autonomy to decide who will serve as your trusted root authority. Whether it's a single central authority or a distributed network of trust, the choice is yours. This shift from relying solely on cloud providers to being in control of your data's destiny sets the stage for a more secure and accountable data-sharing environment.
                        </li><li className="mt-3">
                        <b>Seamlessly Connect Data Sources and AI Models</b><br/>
                        Our partnership with VMware enables you to seamlessly connect any data source and AI models to confidential collaboration. It's a plug-and-play experience with an emphasis on trust but verify. This streamlined approach facilitates effortless data integration, allowing you to harness the power of your data while maintaining the highest standards of security and confidentiality.
                                  </li>
                    </ol>
                    <center><img
                    src="/assets/images/articles/article1-2.png"
                    className="blog-picture"></img></center>
                  </p>
              </Col>
            </Row>
            <Row className="vertical-content ">
              <Col lg={12}><p>
              This collaboration is not just about technology—it's about solving challenges that were previously insurmountable within your data silos. 
              <br/><br/>Together with VMware, we are breaking down barriers, unleashing the potential of confidential data collaboration, and setting new industry benchmarks. It's a significant step toward realizing the vision of Datavillage - Confidential Data Collaboration multi-cloud.
              <br/><br/>
              🚀 As we move forward, we invite you to join us on this exciting journey. Together, we will harness the power of technology, innovation, and collaboration to create a brighter and more secure future for confidential data sharing. 

<br/>With VMware by our side, the possibilities are limitless, and the future of data collaboration has never looked more promising.

<br/>Stay tuned for more updates as we embark on this transformative partnership, driving the industry toward a new era of secure, transparent, and efficient confidential data collaboration. 🚀
</p>
        
              </Col>
            </Row>
            <br/><br/><br/>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Article;
