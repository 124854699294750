import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import parse from "html-react-parser";

class StepsBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.cases.map((usecase, key) => (
          <Col lg={4} key={key}>
            <div
              className={
                usecase.isActive
                  ? "text-center pricing-box bg-white hover-effect price-active"
                  : "text-center pricing-box hover-effect"
              }
            >
             
              <center><div className="icon-background">
              <h1><i className={usecase.icon}></i></h1>
              </div></center>
            
              <p className="pt-3 text-muted">{parse(usecase.desc)}</p>
              <h1><i className='mdi mdi-arrow-down-bold icon-arrow'></i></h1>
              <h5>{parse(usecase.sol)}</h5>
             
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default StepsBox;
