import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
//Import Section Title
import SectionTitle from "../common/section-title";

class FraudPrevention extends Component {
 
  render() {
    return (
      <React.Fragment>
        <section className="section">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Collaborative Fraud Prevention"
              />
        
            <Row className="mt-3">

            <Col lg={6} >
              <div className="advantage-box-2  hover-effect">
               <p className="text-center  text-uppercase f-14">Create and share</p>
                <h2 className="text-center">Community score</h2>
                <br/>
                <ul className="list-unstyled text-left ">
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Create confidential </b> collaboration on Datavillage
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Connect your encrypted list</b> with other participants
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;Integrate with your <b>claim process</b>
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Perform community score</b> on encrypted data
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;<b>Get community score</b> or live alerts
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;Use Datavillage as <b>trusted intermediary</b>
                        </li>
                    </ul>
                    <br/>
                    <p className="text-center"><Link
                      to="/contactdemo">
                          <Button
                            color="none"
                            type="button"
                            className="btn  btn-primary">
                            Talk to an expert
                          </Button></Link></p>
                  
              </div>
             </Col>
             <Col lg={6} ><br/>
             <img
                    src="assets/images/solutions/fraud-video-1.gif"
                    alt="collaborative anti-money laundering"
                    width="95%"
                  />

             </Col>
            </Row>
            <br/><br/>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default FraudPrevention;