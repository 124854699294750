import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import StandardsBox from "./standards-box";

class Standards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [
        {
          title: "Multi-cloud",
          desc: "The solution is designed to run on multiple cloud using <b>K8S</b> technology for <b>scalability</b> and <b>business continuity</b>. ",
          icon: "pe-7s-cloud-upload"
        },
        {
          title: "Open standards",
          desc: "The solution is built on open sources and <b> standards protocols</b> which make it <b>interoperable</b> with legacy systems.",
          icon: "pe-7s-box2"
        },
        {
          title: "API first",
          desc: "The <b>API-first</b> approach ensures integrability, maintainability <br/>and future-proofing.",
          icon: "pe-7s-config"
        }
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section pt-5" id="pricing">
          <Container>
            {/* section title */}
            <br/> <br/>
            <SectionTitle
              title="Built on market standards"
              />
            <Row className="mt-5">
              {/* cases box */}
              <StandardsBox cases={this.state.cases} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Standards;
