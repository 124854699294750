import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";

class Colors extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="media-header">
        <Container>
            {/* section title */}
            <SectionTitle
              title="Colors"
              />
            <Container>
              <Row className="vertical-content ">
                
                <Col lg={6}>
                <p className="mt-4"><b>Neon blue</b> • RGB: 0 | 255 | 255 • HEX: #00FFFF</p>
                <p className="mt-4"><b>Twilight blue</b> • RGB: 24 | 42 | 77 • HEX: #182A4D</p>
                <p className="mt-4"><b>Light grey</b> • RGB: 248 | 249 | 250 • HEX: #F8F9FA</p>
                <p className="mt-4"><b>White</b> • RGB: 99 | 255 | 217 • HEX: #FFFFFF</p>
                </Col>
                <Col lg={6}>
                <img
                    src="assets/images/logos/colors.png"
                    alt="Datavillage colors"
                    style={{maxHeight:350+'px'}}
                  />
                </Col>
              </Row>
            </Container>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Colors;