import React, { Component } from "react";
import { Container, Row,Col,Button } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";



//Import Pricing
import CasesBox from "./cases-box";

class UseCaseByIndustryAdvantages extends Component {
  
  render() {
    return (
      <React.Fragment>
        <section className="section section-lg bg-web-desc" id="cases">
          
          <div className="bg-overlay">
          </div>
          <SectionTitle className="section-title text-center text-white"
              title="How Datavillage can help"
              />
          <Container>
            
          <Row className="mt-3">
            <Col lg={4} >
              <div className="advantage-box-1  hover-effect">
                <p className="text-center  text-uppercase f-14">Identify &amp; learn</p>
                <h3 className="text-center">Use case</h3>
                <br/>
                <p className="f-20 text-center">Discover the right use case that matches your need and your data. <br/><br/>Learn the principles of privacy-enhancing technologies, their opportunities, and how to apply them to unlock your use case.</p>
              </div>
            </Col>
            <Col lg={4} >
              <div className="advantage-box-2  hover-effect">
               <p className="text-center  text-uppercase f-14">Setup &amp; validate</p>
                <h3 className="text-center">Solution</h3>
                <br/>
                <p className="f-20 text-center  ">Set up and activate the necessary tools and governance.<br/><br/>Integrate with your existing systems (data and algorithms) and validate the benefits in a real environment.</p>
   
              </div>
             </Col>
             <Col lg={4} >
              <div className="advantage-box-3  hover-effect">
                <p className="text-center  text-uppercase f-14">Enable</p>
                <h3 className="text-center">New data value</h3>
                <br/>
                
                <p className=" text-center f-20">Unlock the business value of sensitive, personal and proprietary data.<br/><br/>Expand your data-driven revenues with trusted data collaborations.</p>
               
              
              </div>
             </Col>
            </Row>
            <Row>
            <Col lg={12}>
            <br/><br/><br/>
              <center>
              <Link
                      to="/contactdemo">
                          <Button
                            color="none"
                            type="button"
                            className="btn btn-primary navbar-button2">
                            Book a session 
                          </Button>
              </Link>
              </center>
            
             
                </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default UseCaseByIndustryAdvantages;
