import React, { Component } from "react";
import { Container, Row,Col } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";



//Import Pricing
import CasesBox from "./cases-box";

class FraudPreventionAdvantages extends Component {
  
  render() {
    return (
      <React.Fragment>
        <section className="section section-lg bg-finance" id="cases">
          
          <div className="bg-overlay-light">
          </div>
          <SectionTitle className="section-title text-center color-cyan"
              title="Higher detection rate and real-time community scoring"
              />
          <Container>

          <Row className="vertical-content ">
              <Col lg={6}>
                <center>
                <img
                    src="assets/images/cases/graph2.png"
                    alt="collaborative anti-money laundering"
                    width="80%"
                  />
                </center>
                <br/>
              </Col>
              <Col lg={6}>
              <center>
                <img
                    src="assets/images/cases/graph1.png"
                    alt="collaborative anti-money laundering"
                    width="80%"
                  />
                </center>
              </Col>
            </Row>
            
          
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default FraudPreventionAdvantages;
