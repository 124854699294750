import React from "react";
import { Container } from "reactstrap";
//Import Section Title
import SectionTitle from "../common/section-title";

class Clients extends React.Component {
  render() {
    return (
      <section className="section" id="clients">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Trusted by customers and partners"
           />
            <div class="slider">
              <div class="slide-track">
                <div class="slide">
                  <img src="assets/images/clients/fluvius.png" width="250" className="clients-img" />
                </div>
                <div class="slide">
                  <img src="assets/images/clients/belfius.png" width="250" className="clients-img-small" />
                </div>
                <div class="slide">
                  <img src="assets/images/clients/elia.svg" width="250" className="clients-img-medium" />
                </div>
                <div class="slide">
                  <img src="assets/images/clients/cfit.png" width="250" className="clients-img" />
                </div>
                <div class="slide">
                  <img src="assets/images/clients/fluxys.png" width="250" className="clients-img" />
                </div>
                <div class="slide">
                  <img src="assets/images/clients/rbc.png" width="250" className="clients-img" />
                </div>
                <div class="slide">
                  <img src="assets/images/clients/vrt.svg" width="250" className="clients-img" />
                </div>
                <div class="slide">
                  <img src="assets/images/clients/sdnk.png" width="250" className="clients-img" />
                </div>
                <div class="slide">
                  <img src="assets/images/partners/vlaamseoverheid.png" className="clients-img" />
                </div>
                <div class="slide">
                  <img src="assets/images/partners/athumi.png" width="250" className="clients-img-medium" />
                </div>
                <div class="slide">
                  <img src="assets/images/clients/spw.png" width="250" className="clients-img-medium" />
                </div>
                <div class="slide">
                  <img src="assets/images/partners/microsoft.png" width="250" className="clients-img-medium" />
                </div>
                <div class="slide">
                  <img src="assets/images/partners/temenos.png" width="250" className="clients-img-medium" />
                </div>
                <div class="slide">
                  <img src="assets/images/partners/ibm-logo.png" width="250" className="clients-img-medium" />
                </div>
                <div class="slide">
                  <img src="assets/images/partners/imec.png" width="250" className="clients-img-medium" />
                </div>
              </div>
            </div>
          </Container>
      </section>
    );
  }
}

export default Clients;
