import React, { Component } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Header from "../../components/Solutions/usecase-by-industry-header";
import Advantages from "../../components/Solutions/usecase-by-industry-advantages";
import Journeys from "../../components/Journeys/journeys";
import Footer from "../../components/Footer/footer";

class Index extends Component {
  
  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage />

        {/* section */}
        <Section />
        
        {/* Header */}
        <Header />
         {/* Advantages */}
         <Advantages />

          {/* Journeys */}
          <Journeys />

        {/* footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index;
