import React, { Component } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import About from "../../components/Company/about";
import Team from "../../components/Company/team";
import Offices from "../../components/Company/offices";
import Partners from "../../components/Partners/partners";
import Form from "../../components/Contact/form";
import Footer from "../../components/Footer/footer";

class Index extends Component {
  
  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage />

        {/* section */}
        <Section />

        {/* About */}
        <About />

        {/* team */}
        <Team />

        {/* About */}
        <Form />


        {/* footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index;
