import React, { Component } from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";


class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-home home-half"
          id="solution"
        >
          <div className="bg-overlay"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="vertical-content">
                  <Col lg={12} className="text-white text-left ">
                    <center>
                      <h1 className="home-title">
                      Learn about data collaboration opportunities, privacy engineering, data regulations ...
                      </h1>
                      
                    </center>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
        
      </React.Fragment>
    );
  }
}

export default Section;

