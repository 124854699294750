import React, { Component } from "react";
import { Container } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";


class Capabilities extends Component {

  render() {
    return (
      <React.Fragment>
        <section className="section pt-6" id="journeys">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Data Collaboration Gateway"
              />
              <img
                src="assets/images/product/product.png"
                alt="Datavillage"
                className="product-picture"
              />
        <br/><br/>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Capabilities;
