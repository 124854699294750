import React, { Component,useRef } from "react";
import { Redirect } from 'react-router'
import { Container, Row, Col, FormGroup } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

class Emailsentdemo extends Component {
   
  constructor(props) {
    super(props);  
  }

  render() {
 
    return (
      <React.Fragment>
        <section className="section " id="contact">
          <Container>
            {/* section title */}
            <SectionTitle
              title="One-to-one session requested, we will contact you shortly."
            />

          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Emailsentdemo;
