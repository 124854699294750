import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import CasesBox from "./overview-box";

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [
        {
          title: "Govern",
          subtitle: "Manage collaboration governances",
          icon: "assets/images/product/overview1.png",
          size: "100%",
          description: "<ul><li><b>Invite</b> data holders, AI model provider and insights consumers</li><li><b>Connect</b> distributed data, AI algorithm without technical skills</li></ul>"
        },
        {
          title: "Run",
          subtitle: "Combine sensitive data and apply AI",
          icon: "assets/images/product/overview2.png",
          size: "83%",
          description: "<ul><li><b>Deploy</b> confidential infrastructure on multi-clouds</li><li><b>Apply</b> protected algorithm or train AI model on encrypted data</li></ul>"
        },
        {
          title: "Get insights",
          subtitle: "Get new insights and fine-tuned AI models",
          icon: "assets/images/product/overview3.png",
          size: "110%",
          description: "<ul><li><b>Publish</b> insights only to trusted participants</li><li><b>Comply</b> regulations through EU certified <b>Data Intermediation</b></li></ul>"
        }
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light"  id="benefits">
          <Container>
          <SectionTitle
              title="Govern, run and get insights"
              />
            <Row className="mt-5">
              {/* cases box */}
              <CasesBox cases={this.state.cases} />
            </Row>
          </Container>
          <br/><br/>
        </section>
      </React.Fragment>
    );
  }
}


export default Overview;