import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import parse from "html-react-parser";

class ArticlesBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.cases.map((usecase, key) => (
          <Col lg={4} key={key}>
            <div
              className={
                usecase.isActive
                  ? " articles-box  hover-effect"
                  : " articles-box  hover-effect"
              }
            >
              
                      
                   

              <h2 className="pt-3">{parse(usecase.title)}</h2>
              <Row>
                <Col lg={5}>
                <p className="text-muted text-white">{parse(usecase.img)}</p>
                </Col>
                <Col lg={7}>
                <p className="text-muted text-white">{parse(usecase.desc)}</p>
                </Col>
              </Row>
              
              <Link
                      to={usecase.link}
                      onClick={this.callModal}
                      className="text-cyan"
                    >Learn more <i className="icon-arrow mdi mdi-arrow-right"></i>
              </Link>
            </div>
          </Col>
        ))}


      </React.Fragment>
    );
  }
}

export default ArticlesBox;
