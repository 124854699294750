import React, { Component } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Architecture from "../../components/Technology/architecture";
import Technologies from "../../components/Technology/technologies";
import Benefits from "../../components/Technology/benefits";
import Standards from "../../components/Technology/standards";
import Footer from "../../components/Footer/footer";

class Index extends Component {
  
  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage />

        {/* section */}
        <Section />
        
        {/* Architecture */}
        <Architecture />

        {/* Technologies */}
        <Technologies />

        {/* Benefits */}
        <Benefits />


        {/* Standards */}
        <Standards />
       
        {/* footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index;
