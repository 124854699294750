import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import parse from "html-react-parser";

class CapabilitiesBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.cases.map((usecase, key) => (
          <Col lg={6} key={key}>
            <div
              className={
                usecase.isActive
                  ? "text-center capabilities-box  hover-effect"
                  : "text-center capabilities-box  hover-effect"
              }
            >
              
                      
                   

              <h2 className="pt-3">{parse(usecase.title)}</h2>
              <p className="pt-3 text-muted text-white">{parse(usecase.desc)}</p>
              <Link
                      to={usecase.link}
                      onClick={this.callModal}
                      className="text-cyan"
                    >Learn more <i className="icon-arrow mdi mdi-arrow-right"></i>
              </Link>
              
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default CapabilitiesBox;
