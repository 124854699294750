import React, { Component } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import GettingStarted from "../../components/Enterprises/getting-started";
import Demo from "../../components/Enterprises/demo";
import Footer from "../../components/Footer/footer";

class Index extends Component {
  
  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage />

        {/* section */}
        <Section />
        
        {/* GettingStarted */}
        <GettingStarted />

        {/* Demo */}
        <Demo />

        {/* footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index;
