import Home from "./pages/home/Index";
import Platform from "./pages/platform/Index";
import Technology from "./pages/technology/Index";
import AntiMoneyLaundering from "./pages/antiMoneyLaundering/Index";
import FraudPrevention from "./pages/fraudPrevention/Index";
import UseCasesIndustry from "./pages/useCasesIndustry/Index";
import Enterprises from "./pages/enterprises/Index";
import Company from "./pages/company/Index";
import Brand from "./pages/brand/Index";
import News from "./pages/news/Index";
import NewsItem1 from "./pages/news/NewsItem1";
import NewsItem3 from "./pages/news/NewsItem3";
import NewsItem4 from "./pages/news/NewsItem4";
import NewsItem5 from "./pages/news/NewsItem5";
import Blog from "./pages/blog/Index";
import BlogItem1 from "./pages/blog/BlogItem1";
import BlogItem2 from "./pages/blog/BlogItem2";
import BlogItem3 from "./pages/blog/BlogItem3";
import Knowledge from "./pages/knowledge/Index";
import Contact from "./pages/contact/Index";
import Emailsent from "./pages/contact/Emailsent";
import Contactdemo from "./pages/contact/Contactdemo";
import Emailsentdemo from "./pages/contact/Emailsentdemo";
import Privacy from "./pages/privacy/Index";

const routes = [
  { path: "/", component: Home },
  { path: "/home", component: Home },
  { path: "/platform", component: Platform },
  { path: "/technology", component: Technology },
  { path: "/collaborative-transaction-monitoring", component: AntiMoneyLaundering },
  { path: "/collaborative-fraud-scoring", component: FraudPrevention },
  { path: "/usecases-by-industry", component: UseCasesIndustry },
  { path: "/enterprises", component: Enterprises },
  { path: "/knowledge", component: Knowledge },
  { path: "/news", component: News },
  { path: "/revolutionizing-confidential-data-collaboration-our-new-partnership-with-VMware", component: NewsItem1 },
  { path: "/datavillage-joinsjthejVMware-technology-alliance-partner-program", component: NewsItem3 },
  { path: "/datavillage-and-service-public-of-wallonia-partner-to-deliver-content-recommendation", component: NewsItem4 },
  { path: "/partnership-announcement-datavillage-and-athumi-shaping-the-future-of-data-collaboration", component: NewsItem5 },
  { path: "/blog", component: Blog },
  { path: "/data-collaboration-the-backbone-of-open-finance", component: BlogItem1 },
  { path: "/leveraging-regulations-the-future-of-collaborative-fraud-prevention", component: BlogItem2 },
  { path: "/why-confidential-computing-is-a-game-change", component: BlogItem3 },
  { path: "/company", component: Company },
  { path: "/brand-assets", component: Brand },
  { path: "/contact", component: Contact },
  { path: "/emailsent", component: Emailsent },
  { path: "/contactdemo", component: Contactdemo },
  { path: "/emailsentdemo", component: Emailsentdemo },
  { path: "/privacy", component: Privacy }
  
];


export default routes;

