import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Pricing
import CasesBox from "./cases-box";

//Import Section Title
import SectionTitle from "../common/section-title";


class UseCaseByIndustryHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      col:4,
      cases: [
        {
          title: "Automated finance",
          category: "Finance",
          desc: "Provide access to financial data and control who and how it is used without disclosure or leakage.<br/><br/><font style='color:#0a1835;'>Guaranteed</font> <h1 style='color:#0a1835;'>100%&nbsp;<font class='f-14'>(audited)</font ></h1> <font style='color:#0a1835;'>information remains confidential throughout the lifecycle.</font>",
          img: "assets/images/solutions/finance-case1.png",
          carriage: ""
        },
        {
          title: "Open data",
          category: "Finance",
          desc: "Enable or improve financial services by accessing new sources of data from other sectors or governments. <br/><br/><font style='color:#0a1835;'> Reduce by</font> <h1 style='color:#0a1835;'>30%&nbsp;<font class='f-14'>(verified)</font></h1> <font style='color:#0a1835;'>the information needed to be requested from customers. </font>",
          img: "assets/images/solutions/finance-case2.png",
          carriage: ""
        },
        {
          title: "Collaborative finance",
          category: "Finance",
          desc: "Collaborate with other financial institutions by combining data while keeping control over it. <br/><br/><font style='color:#0a1835;'>Increase by </font> <h1 style='color:#0a1835;'>50%&nbsp;<font class='f-14'>(verified)</font></h1><font style='color:#0a1835;'>the efficiency rather than doing it alone. </font>",
          img: "assets/images/solutions/finance-case3.png",
          carriage: ""
          
        }
      ],
    };
  }


  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="media-header">
          <Container>
          <center><h1 className='f-50'><i className='pe-7s-angle-down-circle'></i></h1></center> 
          <Row className="vertical-content">
             <Col lg={12} className="text-center">
               {/* section title */}
                <div className="features-box2">
                <h3>Use data belonging to other organizations or let them use your data, <br/>without ever seeing or acquiring a copy of that data.</h3>
                </div>
              </Col>
          </Row>
          <Row className="mt-5">
              {/* cases box */}
              <CasesBox cases={this.state.cases} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default UseCaseByIndustryHeader;