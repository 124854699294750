import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";

class Image2 extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="bg-white" >
        <Container>
            <Container>
              
              <Row className="vertical-content ">
                <Col lg={12}>
                <img
                    src="assets/images/logos/Logo_full_dark.png"
                    alt="Datavillage"
                    style={{maxWidth:650+'px'}}
                  />
                  <br/><br/><br/>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Image2;