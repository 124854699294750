import React, { Component } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Logos from "../../components/Brand/logos";
import Colors from "../../components/Brand/colors";
import Image1 from "../../components/Brand/image1";
import Image2 from "../../components/Brand/image2";
import Footer from "../../components/Footer/footer";

class Index extends Component {
  
  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage />

        {/* section */}
        <Section />

        {/* Logos */}
        <Logos />

        {/* Colors */}
        <Colors />

         {/* Images */}
         <Image1 />
         <Image2 />

        {/* footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index;
