import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import parse from "html-react-parser";

class ArticlesBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.cases.map((usecase, key) => (
          <Col lg={4} key={key}>
         
            <div
              className={
                usecase.isActive
                ? " news-box  hover-effect"
                : " news-box  hover-effect"
            
              }
            >
           
             {parse(usecase.img)}<br/>
              <h2>{parse(usecase.title)}</h2>
              <p >{parse(usecase.desc)}</p>
              <p >{parse(usecase.date)}</p>
              <Link
                      to={usecase.link}
                      onClick={this.callModal}
                    
                    >Learn more <i className="icon-arrow mdi mdi-arrow-right"></i>
              </Link>
              <br/><br/>
            </div>
            
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default ArticlesBox;
