import React, { Component } from "react";
import { Row, Col } from "reactstrap";

class SvgIcon extends Component {
  render() {
    return (
      <React.Fragment>
        <svg width={this.props.width} viewBox="0 0 55 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 18.4702V42.5298C0 44.8321 1.232 46.9598 3.245 48.1055L24.255 60.1407C26.268 61.2864 28.732 61.2864 30.745 60.1407L51.755 48.1055C53.768 46.9598 55 44.8321 55 42.5298V18.4702C55 16.1679 53.768 14.0402 51.755 12.8945L30.745 0.85927C28.732 -0.286423 26.268 -0.286423 24.255 0.85927L3.245 12.8945C1.232 14.0402 0 16.1679 0 18.4702Z" fill="#00FFFE"></path>
                     <defs>
                      <filter id="filter8" x="17.7861" y="16.6221" width="24.2523" height="27.1195" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
                      <feOffset></feOffset>
                      <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"></feColorMatrix>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
                      </filter>
                      </defs>
                      </svg>
      </React.Fragment>
    );
  }
}

export default SvgIcon;
