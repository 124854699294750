import React, { Component } from "react";
import { Col } from "reactstrap";

class TeamBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.clients.map((client, key) => (
          <Col lg={3} key={key}>
            <div className="team-box hover-effect mt-4">
              <img
                src={client.image}
                alt="client"
                className="img-fluid d-block "
              />
              
              <div className="team-decs">
                <br/><br/>
              </div>
              <h5 className="text-center text-uppercase pt-3">
                {client.title} <br/>
                <div className="team-decs">
                  <p className="text-capitalize">
                    {client.subTitle}
                    <br/>
                    <center><a href={client.linkedin} target="_blank"><i className="mdi mdi-linkedin social-icon"></i></a></center>
                  </p>
                </div>
                
                <br/>
                </h5>
            </div>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default TeamBox;
