import React, { Component } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Header from "../../components/Solutions/fraud-prevention-header";
import Advantages from "../../components/Solutions/fraud-prevention-advantages";
import Faq from "../../components/Solutions/fraud-prevention-faq";
import Solution from "../../components/Solutions/fraud-prevention";
import Footer from "../../components/Footer/footer";

class Index extends Component {
  
  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage />

        {/* section */}
        <Section />
        
        {/* Header */}
        <Header />

  
        {/* Solution */}
        <Solution />

        {/* Faq */}
        <Faq />

        {/* footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index;
