import React, { Component } from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";


class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-home home-half home-registration2"
          id="platform"
        >
          <div className="bg-overlay"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
              <Row className="vertical-content">
                  <Col lg={7} className="text-white text-left">
                    <br/><br/><br/>
                    <p className="home-desc-2 home-subtitle-width-100">
                  Plug - Protect - Process</p>
                  <h1 className="home-title">
                   Privacy Preserving <font style={{color:'aqua'}}>Gateway</font>
                    </h1>
                  
                   
                   
                  </Col>
                  <Col lg={5} >
                    <img src="assets/images/technology/gateway.png" width="120%"/>
                  
                  </Col>
                  
                </Row>

              </Container>
            </div>
          </div>
        </section>
        
      </React.Fragment>
    );
  }
}

export default Section;

