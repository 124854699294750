import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import ProductBenefitsBox from "./product-benefits-box";

class ProductBenefits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [
        {
          title: "Smart",
          desc: "Empower business users the flexibility to tailor use cases or reuse solution accelerators and AI models.",
          icon: "pe-7s-light"
        },
        {
          title: "Simple",
          desc: "With the intuitive UI and APIs, business users and data scientists work together to unlock AI with sensitive data.",
          icon: "pe-7s-add-user"
        },
        {
          title: "Scalable",
          desc: "Integrate with your existing tech stack, using an open and modular platform relying on enterprise standards.",
          icon: "pe-7s-plugin"
        },
        {
          title: "Safe",
          desc: "Ensure privacy by design governance and rely on PETs technologies in a secure, compliant platform.",
          icon: "pe-7s-safe"
        }
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section section-lg bg-web-desc" id="pricing">
        <div className="bg-overlay">
        </div>
          <Container>
            {/* section title */}
            <SectionTitle className="section-title text-center text-white"
              title="Do more with sensitive data"
              />
            <Row>
              {/* cases box */}
              <ProductBenefitsBox cases={this.state.cases} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default ProductBenefits;
