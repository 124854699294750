import React, { Component } from "react";

import NavbarPage from "../../components/Navbar/Navbar_Page";
import Section from "./section";
import Clients from "../../components/Clients/clients";
import Product from "../../components/Product/Capabilities";
import Business from "../../components/Business/business";
import Cases from "../../components/Cases/cases";
import Finance from "../../components/Cases/finance";
import Footer from "../../components/Footer/footer";

class Index extends Component {

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage/>

        {/* section */}
        <Section />

        {/* clients */}
        <Clients />

        <br/>
        {/* Business */}
        <Business />

        {/* Cases */}
        <Cases />

        {/* Finance */}
        <Finance />


         {/* product */}
       <Product />

        {/* footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index;
