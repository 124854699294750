import React, { Component } from "react";
import { Container, Row,Col } from "reactstrap";

//Import Section Title
import SectionTitle from "../../common/section-title";


class Article extends Component {
  constructor(props) {
    super(props);
    
  }

  render() {
    return (
      <React.Fragment>
        <section className="section">
        <Container>
          <SectionTitle className="section-title text-center "
              title="Datavillage SRL Joins <br/>the VMware Technology Alliance Partner Program"
              />
          <Row className="vertical-content ">
              <Col lg={12}>
              <div ><p>
              <b>Palo Alto, 10/04/23 — Datavillage SRL,  share insights, not data, today announced it has joined the VMware Technology Alliance Partner (TAP) program .</b> 
              <br/><br/>Members of the TAP program collaborate with VMware to deliver innovative solutions for virtualization and cloud computing. 
              <br/>The diversity and depth of the TAP ecosystem provides customers with the flexibility to choose a partner with the right expertise to satisfy their unique needs.
              <br/><br/><a href="https://www.vmware.com/partners/work-with-vmware/tap.html" target="_blank"><u>The Technology Alliance Partner (TAP) program</u></a> is the start of the VMware journey for software and hardware vendors. The TAP program enables hardware and software vendors with the tools and resources they need to build and deliver their solutions on VMware infrastructure. Partners can develop, test, integrate, certify/validate and package products with VMware products and services and then publish their solutions on the VMware Marketplace to our shared customers.

              <br/><br/><i>“We welcome Datavillage SRL as a valued member of the VMware TAP program,”</i> said Jenn Wahl, director, Technology Alliance Partner Program, VMware. 
              <br/><i>“This membership means customers can take full advantage of a streamlined cloud infrastructure experience. By joining the program, Datavillage SRL is working with VMware to develop technologies that can transform customers’ environments.”</i>

              <br/><br/><b>About Datavillage SRL</b>

              <br/>At Datavillage, we have a distinct vision: reshaping how organizations work together using data. 
              Our goal is to empower companies to leverage their sensitive data without compromising its security or privacy. 
              Our groundbreaking platform is revolutionizing the industry. It lets organizations merge data and utilize AI, all the while upholding data privacy. 
              <br/>Instead of sharing raw data, we share valuable insights in a secure manner. By bringing together varied data streams and employing cutting-edge AI in a protected space, we're pioneering a new era of safe data collaboration.

              <br/><br/># # #

              <br/>Contacts:

              <br/>Frederic Lebeau, CEO (frederic@datavillage.me)

              <br/><i>VMware is a registered trademark of VMware, Inc. in the United States and other jurisdictions. All other marks and names mentioned herein may be trademarks of their respective companies.     </i>
              </p>

</div>
              </Col>
            </Row>
            <br/><br/>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Article;
