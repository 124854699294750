import React, { Component } from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";


class Finance extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-finance home-half"
          id="finance"
        >

          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="vertical-content">
                  <Col lg={12} className="text-left ">
                  <h1 className="text-white">
                    "Datavillage is making cross organisational data collaboration a reality in an increasingly opened and transparent world."
                    </h1>
                    <p className="pt-3 home-desc text-white">
                    Nordic Fintech Magazine</p>
                    <a className="text-white" href="https://www.fintechbelgium.be/news/confidential-datacollaboration-in-fintech" target="_blank">Read full article <i className="mdi mdi-arrow-right"></i></a>
                   
                    </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>
        
      </React.Fragment>
    );
  }
}

export default Finance;

