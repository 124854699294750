import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";



//Import steps
import StepsBox from "./steps-box";

class GettingStarted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [
        {
          desc: "Moving from data sharing to data collaboration",
          sol: "Unlike data sharing, which moves and copies data from one entity to another, data collaboration allows multiple entities (organisations and consumers) to keep control and combine live data with running algorithms for a shared purpose.",
          icon: "pe-7s-share icon-with-background"
        },
        {
          desc: "Using of data protection & privacy engineering ",
          sol: "This is a key factor for building trusted data collaboration, where organisations and consumers submit data without disclosing personal data or sensitive business information.<br/><br/>",
          icon: "pe-7s-safe icon-with-background"
        },
        {
          desc: "Governing with transparency and control",
          sol: "Governance of data collaboration is essential to transparency and a sense of control for the participating organizations and consumers.<br/><br/><br/>",
          icon: "pe-7s-settings icon-with-background"
        }
      ],
    };
  }
  render() {
    return (
      <React.Fragment>
        <section className="section" id="media-header">
        <Container>
            {/* section title */}
            <SectionTitle
              title="Getting started"
              />
              <Row className="mt-5">
              {/* cases box */}
              <StepsBox cases={this.state.cases} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default GettingStarted;
