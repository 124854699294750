import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Pricing
import CasesBox from "./articles-box";

class Cases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [
        {
          title: "New partnership with Athumi",
          img: "<img width='100%' src='assets/images/articles/article5.png'/>",
          desc: "Datavillage is thrilled to announce a partnership with Athumi, a collaboration set to revolutionise the realm of data ecosystems through our shared commitment to secure and trusted data collaboration.",
          link:"/partnership-announcement-datavillage-and-athumi-shaping-the-future-of-data-collaboration",
          date: "November 29th 2023"
        },
        {
          title: "Datavillage and Service Public of Wallonia",
          img: "<img width='100%' src='assets/images/articles/article4.png'/>",
          desc: "Datavillage and Service Public of Wallonia partner to deliver content recommendation with privacy for their governmental data catalogue.<br/><br/><br/>",
          link:"/datavillage-and-service-public-of-wallonia-partner-to-deliver-content-recommendation",
          date: "November 09th 2023"
        },
        {
          title: "New partnership with VMWare",
          img: "<img width='100%' src='assets/images/articles/article1.png'/>",
          desc: "A new partnership that underlines our commitment to pushing the boundaries of multi-cloud confidential computing.<br/><br/><br/><br/>",
          link:"/revolutionizing-confidential-data-collaboration-our-new-partnership-with-VMware",
          date: "October 6th 2023"
        }
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section" id="pricing">
          <Container>
            
            <Row>
              {/* cases box */}
              <CasesBox cases={this.state.cases} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Cases;
