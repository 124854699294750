import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
//Import Section Title
import SectionTitle from "../common/section-title";

class AntiMoneyLaundering extends Component {
 
  render() {
    return (
      <React.Fragment>
        <section className="section  bg-light" >
          <Container>
            {/* section title */}
            <SectionTitle
              title="FAQs"
              />
        
            <Row className="mt-3">

            <Col lg={12} >
              <div className="advantage-box-1  hover-effect">
                <p className="f-24">What types of insights and outcomes can be generated through trusted data collaboration on our platform?</p>
                <p className="f-20">Our platform empowers organizations to derive a wide range of insights and outcomes in the fight against financial crime. These include but are not limited to anomaly detection, risk score, and predictive analytics. By securely aggregating and analyzing data from multiple sources, our platform enables the discovery of actionable intelligence while preserving the confidentiality of sensitive information.</p>
              </div>
             </Col>
             <Col lg={12} >
             <div className="advantage-box-1  hover-effect mt-3">
                <p className="f-24">How does our platform ensure the security and privacy of sensitive information?</p>
                <p className="f-20">Our platform utilizes confidential computing technology, which ensures that sensitive data remains encrypted and protected even while being processed. This means that data is never exposed to any party, including ourselves, during collaboration. Additionally, access controls and encryption protocols are strictly enforced to maintain the utmost security and privacy standards.</p>
              </div>
             </Col>
             <Col lg={12} >
             <div className="advantage-box-1  hover-effect mt-3">
                <p className="f-24">Can multiple parties collaborate on the platform without risking data breaches or leaks?</p>
                <p className="f-20">Yes, our platform is specifically designed to facilitate secure collaboration among multiple parties while mitigating the risk of data breaches or leaks. Through the use of confidential collaboration spaces and advanced encryption techniques, each party can contribute their data and insights without exposing sensitive information to others. This allows for effective collaboration in fighting financial crime without compromising privacy.</p>
              </div>
             </Col>
             
             <Col lg={12} >
             <div className="advantage-box-1  hover-effect mt-3">
                <p className="f-24">How does your platform enable the training of AI models and application of algorithms without disclosing confidential data and keeping the model protected?</p>
                <p className="f-20">Our platform leverages privacy-preserving techniques such as confidential AI and secure federated learning to train AI models and apply algorithms without exposing underlying data. By keeping data or even computation tasks across participants and encrypting data at rest, in transit and in use, we ensure that confidential information remains protected throughout the process. This enables organizations to derive valuable insights and intelligence while safeguarding sensitive data and AI model IP.</p>
              </div>
             </Col>
             <Col lg={12} >
             <div className="advantage-box-1  hover-effect mt-3">
                <p className="f-24">Is our platform compliant with regulatory requirements, particularly in the context of financial crime prevention and anti-money laundering regulations?</p>
                <p className="f-20">Yes, ensuring compliance with regulatory requirements is a top priority for our platform. We have designed our solution to adhere to relevant regulations governing data privacy, security, and financial crime prevention, including but not limited to GDPR and AML regulations. Our platform's compliance framework is continuously updated to reflect the latest regulatory developments and standards.</p>
              </div>
             </Col>
             <Col lg={12} >
             <div className="advantage-box-1  hover-effect mt-3">
                <p className="f-24">How does your platform address the regulatory challenges associated with sharing and processing sensitive financial data across multiple parties?</p>
                <p className="f-20">Our platform employs a robust compliance framework that incorporates industry best practices and regulatory guidelines to address the challenges of sharing and processing sensitive financial data. This includes implementing strong encryption protocols, access controls, and audit trails to ensure data integrity, confidentiality, and traceability throughout the collaboration process.</p>
              </div>
             </Col>
            </Row>
            <br/><br/><br/>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AntiMoneyLaundering;