import React, { Component } from "react";
import { Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

//Import Svg icon
import SvgIcon from "../common/svg-icon";

//Import Section Title
import SectionTitle from "../common/section-title";


class Steps extends Component {
  

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light pt-5" id="pricing">
          <Container>
          <SectionTitle
              title="Find out how your organization can benefit from data collaboration"
              />
             <h3>
             Our one-to-one session will provide
              </h3>   
              
              <ul className="list-unstyled mt-4 features-item-list">
                    <li className="">
                    <h3><SvgIcon width="13px"/>&nbsp;&nbsp;A demo of our data collaboration platform.</h3></li>
                    <li className="">
                    <h3><SvgIcon width="13px"/>&nbsp;&nbsp;An introduction to use cases.</h3></li>
                    <li className="">
                    <h3><SvgIcon width="13px"/>&nbsp;&nbsp;An overview of privacy engineering.</h3></li>
 
                  </ul>
                  <img width="500px" src="assets/images/arrow.png"/>
                  <center>
                  <Link
                    to="/contactdemo"
                    className="btn btn-primary navbar-button margin-less"
                  >
                    Book a demo
                  </Link>
                  </center>

          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Steps;
