import React, { Component } from "react";
import { Container, Row,Col,Button } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";

class Cases extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-home "
          id="cases"
        >
          <div className="bg-overlay"></div>
          <Container>
          <Row className="vertical-content ">
              <Col lg={5}>
              <div className="features-box">
                <p className="text-white">
                Finance
                  </p>
                  <h1 className=" text-white">
                  Better finance with secure access to more data
                  </h1>
                  <p className="text-white">
                  Use sensitive data and fine-tune AI to better detect fraudsters, monitor transactions or benchnmark suspicious activities. 
                  </p>
                  <Link
                      to="/collaborative-anti-money-laundering"
                    >
                      <Button
                            color="none"
                            type="button"
                            className="btn btn-primary">
                             Collaborative monitoring <i className="mdi mdi-arrow-right"></i>
                          </Button>
                     
                    </Link>
                </div>
              </Col>
              <Col lg={7}>
                <div className="features-img">
                  <img
                    src="assets/images/product/platform.png"
                    alt="Datavillage"
                    className="case-picture"
                  />
                </div>
              </Col>
            </Row>
            <br/><br/>
            <Row className="vertical-content ">
              <Col lg={12}>
              <h1 className="color-cyan text-center">
                  Reduce false positives and increase detection rate
                  </h1>
              </Col>
            </Row>
            <Row className="vertical-content">
              <Col lg={6}>
                <center>
                <img
                    src="assets/images/cases/graph2.png"
                    alt="collaborative anti-money laundering"
                    width="80%"
                  />
                </center><br/>
              </Col>
              <Col lg={6}>
              <center>
                <img
                    src="assets/images/cases/graph1.png"
                    alt="collaborative anti-money laundering"
                    width="80%"
                  />
                </center>
              </Col>
            </Row>
            <br/><br/>
          </Container>
          
        </section>
      </React.Fragment>
    );
  }
}

export default Cases
;
