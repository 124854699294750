import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";

class Offices extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="media-header">
        <Container>
            {/* section title */}
            <SectionTitle
              title="Our offices"
              />
            <Container>
              <Row className="vertical-content ">
                
                <Col lg={5}>
                <iframe width="500" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.openstreetmap.org/export/embed.html?bbox=4.697765707969666%2C50.886050516603596%2C4.700018763542176%2C50.887299277829996&amp;layer=mapnik&amp;marker=50.88667490140107%2C4.69889223575592"></iframe>
                </Col>
                <Col lg={3}>
                <img
                    src="assets/images/dehoorn.jpeg"
                    alt="de Hoorn"
                    height="350px"
                  />
                </Col>
                <Col lg={4}>
                <h3>Leuven</h3>
                De Hoorn<br/>
                Sluisstraat 79<br/>
                3000 Leuven, Belgium
                <br/><br/>
                <i className="pe-7s-mail-open"></i>&nbsp; <a href="mailto:contact@datavillage.me">contact@datavillage.me</a>
                
                </Col>
              </Row>
              <Row className="vertical-content ">
                <Col lg={3}>
                <img
                    src="assets/images/charleroi.png"
                    alt="Charleroi"
                    height="250px"
                  />
                </Col>
                <Col lg={4}>
                <h3>Charleroi</h3>
                Square des martyrs 1<br/>
                6000 Charleroi, Belgium
                <br/><br/>
                <i className="pe-7s-mail-open"></i>&nbsp; <a href="mailto:contact@datavillage.me">contact@datavillage.me</a>
                
                </Col>
                <Col lg={5}>
                <iframe width="500" height="350" src="https://www.openstreetmap.org/export/embed.html?bbox=4.437924027442933%2C50.40387799979427%2C4.4424301385879525%2C50.40640124980322&amp;layer=mapnik&amp;marker=50.405139641592136%2C4.440177083015442"></iframe>
                </Col>
              </Row>
            </Container>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Offices;