import React, { Component } from "react";
import { Container, Row,Col } from "reactstrap";

//Import Section Title
import SectionTitle from "../../common/section-title";


class Article extends Component {
  constructor(props) {
    super(props);
    
  }

  render() {
    return (
      <React.Fragment>
        <section className="section">
        <Container>
          <SectionTitle className="section-title text-center "
              title="Datavillage and Service Public of Wallonia partner to deliver content recommendation with privacy for their governmental data catalogue"
              />
          <Row className="vertical-content ">
              <Col lg={12}>
              <div><p className="mt-4">
              This innovative partnership embarked on a quest to redefine the user experience within data catalogues. Datavillage, with its expertise in data management, and the Public Service of Wallonia, with its commitment to serving the community, came together to weave a new narrative in the world of data interaction.<br/><br/>

              Their project was ambitious: to leverage user information in a way that each individual's interaction with the data catalogue would become a personalized journey. The goal was to move beyond the static, impersonal lists of results that we've all grown accustomed to, and instead, offer a curated selection that resonates with the individual preferences and needs of each user.
              </p>
              </div>
              <br/><br/>
              <center><img src="/assets/images/articles/article4-1.png" className="blog-picture"></img></center>
              <p className="mt-4">
              The approach was to create a living, breathing digital profile for each user. This profile would be a blend of self-reported information—like one's profession and location—and the digital breadcrumbs left behind as users explored the geoportal of the Walloon Government (<a href="https://geoportail.wallonie.be/" target="_blank">https://geoportail.wallonie.be/ </a>), such as search history and downloaded content.<br/><br/>

              As users set out to find information, their profiles would set out on a parallel quest, engaging with the metadata base—a detailed map of the data catalogue's offerings. Through a sophisticated graph algorithm, akin to a digital matchmaker, connections were made, scoring each piece of metadata to find the most relevant results.<br/><br/>

              Datavillage ensured that the sanctity of personal data was upheld by securing it within a SOLID pod—a digital stronghold where users are the sovereigns of their data kingdom. This was a significant shift from the traditional data collection practices, empowering users with complete control over their personal information in compliance with GDPR.<br/><br/>

              The project was designed to be intuitive, with the graph mining algorithm initially fine-tuned by the insights from the Public Service of Wallonia's team, who brought their understanding of the community's needs to the table. As the project matured, the algorithm was set to self-adjust, learning from the patterns of data to enhance its precision.<br/><br/>
              </p>
              <br/><br/>
              <center><img src="/assets/images/articles/article4-2.png" className="blog-picture"></img></center>
              <p className="mt-4">
              Data processing took place within a secure environment on the Datavillage’s Data Collaboration Platform ensuring privacy and confidentiality whole the data streams, compliant with standards like DCAT for data content and format, and CSW for access APIs, fed into the system seamlessly. The results, tailored preference scores, were delivered through a web service, ready to integrate with various applications.
              </p>
              <br/><br/>
              <center><img src="/assets/images/articles/article4-3.png" className="blog-picture"></img></center>
              <p className="mt-4">
                The beauty of this project lies in its modularity and its commitment to open-source principles, with the exception of the data cage. This ensures that the solutions developed by Datavillage and the Public Service of Wallonia can be adapted and adopted in other contexts, potentially benefiting open data initiatives and Geonetwork open-source solutions under the INSPIRE directive.<br/><br/>

              As this partnership between Datavillage and the Public Service of Wallonia continues to evolve, we're reminded of the power of collaboration in creating user-centric solutions that respect privacy while delivering personalized experiences. Stay with us on this journey as we explore the intersections of technology, service, and community.<br/><br/>

              </p>
              </Col>
            </Row>
          
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Article;
