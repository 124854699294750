import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Svg icon
import SvgIcon from "../common/svg-icon";

class Architecture extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="benefits">
        
          <Container>
            <SectionTitle
              title="A Gateway based architecture"
              />
              <Row>
                <Col lg="12">
                  <div className="features-box">
                <h3>

                Just like infrastructure security, web application security, or API security, we believe that <b>data security</b> must be reinforced externally and transparently to the running systems processing that data. 
                
                <br/>This guarantees segregation of duties, better control, improved auditability, and a high level of security, enabling  <b>"trust but verify"</b> principles.
                 <br/><br/>
                <b>Our architecture</b> is based on the common concept of a <b>Gateway</b>, allowing data to be treated as <b>confidential data products</b>. Connecting your data contracts and defining protection policies enables processing while ensuring full condidentiality and privacy.<br/><br/>
                <center>
                <img
                     src="assets/images/technology/architecture.png"
                    alt="Datavillage privacy perserving gateway"
                    width="600px"
                  />
                </center>
                <br/>
                Leveraging new <b>Privacy Enhancement Technologies (PETs)</b>, our unique <b>Privacy Preserving Gateway</b> enforces novel encryption techniques and granular user consent mechanisms for incoming and outgoing data flows. 
                <br/>This ensures that proprietary, sensitive, and personal data will never be accessed without valid consent and remains <b>encrypted at all times, even during use</b>.
                </h3>
                  
                </div>
                </Col>
              </Row>
        
                
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Architecture;