import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import TeamBox from "./team-box";

//Import Section Title
import SectionTitle from "../common/section-title";



class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [
        {
          image: "assets/images/employees/frederic.png",
          title: "Lebeau Frederic",
          subTitle: "Co-founder & CEO",
          linkedin: "https://www.linkedin.com/in/lebeaufrederic/"
        },
        {
          image: "assets/images/employees/philippeh.png",
          title: "Philippe Haenebalcke",
          subTitle: "Co-founder & CCO",
          linkedin: "https://www.linkedin.com/in/philippe-haenebalcke-4255b82/"
        },
        {
          image: "assets/images/employees/quentin.png",
          title: "Quentin Felice",
          subTitle: "Co-founder & COO",
          linkedin: "https://www.linkedin.com/in/quentinfelice/"
        },
        {
          image: "assets/images/employees/coralie.png",
          title: "Coralie Brancart",
          subTitle: "CFO",
          linkedin: "https://www.linkedin.com/in/coralie-brancart-480a6626/"      
        },
        {
          image: "assets/images/employees/arne.png",
          title: "Arne Goeteyn",
          subTitle: "Confidential computing",
          linkedin: "https://www.linkedin.com/in/arne-goeteyn-b470671ba/"
        },
        {
          image: "assets/images/employees/philippe.png",
          title: "Michiel Rogissart",
          subTitle: "Data engineering",
          linkedin: "https://www.linkedin.com/in/michiel-rogissart-aba704259/"
        },
        {
          image: "assets/images/employees/loic.png",
          title: "Loic Quertenmont (PHD)",
          subTitle: "Data science",
          linkedin: "https://www.linkedin.com/in/loicquertenmont/"
        },
        {
          image: "assets/images/employees/justin.png",
          title: "Justin Sirjacques",
          subTitle: "Full-stack",
          linkedin: "https://www.linkedin.com/in/justin-sirjacques-a8991a148/"
        },
        {
          image: "assets/images/employees/benjamin.png",
          title: "Benjamin Docquir",
          subTitle: "Legal (Data | AI | Privacy)",
          linkedin: "https://www.linkedin.com/in/benjamindocquir/"      
        },
        {
          image: "assets/images/employees/richard.png",
          title: "Richard Matton",
          subTitle: "Busdev (UK & Nordics)",
          linkedin: "https://www.linkedin.com/in/richard-maton-6b4353/"
        }
      ],
      boardmembers:[
        {
          image: "assets/images/employees/jan.png",
          title: "Jan De Roeck",
          subTitle: "Chairman of the board",
          linkedin: " https://www.linkedin.com/in/janderoeck1/"      
        },
        {
          image: "assets/images/employees/benny.png",
          title: "Benny Verhaeghe",
          subTitle: "Board Member",
          linkedin: "https://www.linkedin.com/in/bennyverhaeghe/"      
        },
        {
          image: "assets/images/employees/mathieu.png",
          title: "Mathieu Demaré",
          subTitle: "Board Member",
          linkedin: "https://www.linkedin.com/in/mathieudemare/overlay/photo/"      
        } 

      ],
    };

   
    
  }

  render() {
    return (
      <React.Fragment>
        <section className="section " id="team">

          <Container>
            {/* section title */}
            <SectionTitle
              title="Meet the growing team" className="section-title text-center"
              />
            <Row className="mt-5">
              <TeamBox clients={this.state.employees} />
            </Row>
            <Row className="mt-5">
              <TeamBox clients={this.state.boardmembers} />
            </Row>
          </Container>
          <div className="bg-pattern-effect">
            <img src="assets/images/bg-pattern.png" alt="dorsin" />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Team;