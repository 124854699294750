import React from "react";
import routes from "./routes";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {routes.map((route, idx) => (
            <Route exact path={route.path} component={route.component} key={idx} />
          ))}
            <Route
            path="/linkedin"
            component={() => {
              window.location.replace('https://www.linkedin.com/company/datavillage');
              return null;
            }}
          />
          <Route
            path="/twitter"
            component={() => {
              window.location.replace('https://twitter.com/datavillageme');
              return null;
            }}
          />
          <Route
            path="/github"
            component={() => {
              window.location.replace('https://github.com/datavillage-me');
              return null;
            }}
          />
          <Route
            path="/docs"
            component={() => {
              window.location.replace('https://docs.datavillage.me/index.html');
              return null;
            }}
          />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
