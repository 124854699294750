import React, { Component } from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";


class Section extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-home home-home"
          id="platform"
        >
          <div className="bg-overlay"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="vertical-content">
                  <Col lg={8} className="text-white text-left">
                    <h1 className="home-title home-subtitle-width-100">
                    Govern and Run <br/>Trusted Data Collaborations
                    </h1>
                    <p className="home-subtitle-width-90">
                    Manage secure environments for processing encrypted data, apply and train protected AI models.<br/>
                    </p>
                    <br/>
                    <Link
                      to="/contactdemo">
                          <Button
                            color="none"
                            type="button"
                            className="btn btn-primary">
                            Request a demo
                          </Button></Link>
                  </Col>
                  <Col lg={4}>
                    <img src="assets/images/product/platform1.png" width="130%" className="section-img"/>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>


        
      </React.Fragment>
    );
  }
}

export default Section;

