import React, { Component } from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";


class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="section bg-contact home-home"
          id="platform"
        >
          <div className="bg-overlay-light"></div>
          <div className="display-table">
            <div className="display-table-cell">
              <Container>
                <Row className="vertical-content">
                  <Col lg={12} className="text-white text-left">
                    <h1 className="home-title home-subtitle-width-100">
                    Get in touch  
                    </h1>
                    
                    <p className="home-subtitle-width-90">
                    Get in touch with Datavillage experts to learn how we can help.
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </section>



      </React.Fragment>
    );
  }
}

export default Section;

