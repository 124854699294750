import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import CasesBox from "./business-box";

class Business extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [
        {
          title: "Create new value",
          desc: "of companies face challenges in training fine-tuned AI models due to data scarcity, biased third-party models, and privacy concerns.",
          icon: "2/3",
          provider: "Mckinsey - HBR",
          link: "/retail"
        },
        {
          title: "More economic benefits",
          desc: "Total data created, captured, duplicated and used is expected to reach a compound average weekly growth of 30% more.<br/><br/>",
          icon: "30%",
          provider: "Statista",
          link: "/platform"
        }
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section-minimal" id="businesses">
          <Container>
          
            <Row className="mt-5">
              {/* cases box */}
              <CasesBox cases={this.state.cases} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}


export default Business;