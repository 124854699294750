import React, { Component } from "react";
import { Container, Row,Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";

class Technologies extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <section className="section section-lg bg-web-desc" id="cases">
          
        <div className="bg-overlay">
        </div>
          <Container>
          <SectionTitle className="section-title text-center text-white"
              title="Plug - Protect - Process data"
              />
          <Row className="vertical-content ">
          
              <Col lg={6}>
                <div className="features-box">
                <p className="text-aqua">
                PLUG
                  </p>
                  <h3 className="text-white">
                  Easily plug in your data by configuring connection profiles and access keys on the Gateway (always encrypted).

                  <br/>Configure your data contracts manually or by using your data governance tool.

                  <br/>Use the default data connector protocols or utilize your custom data APIs.
                  </h3>
                  
                </div>
              </Col>
              <Col lg={6}>
              <br/><br/><br/>
                <div className="features-img features-right text-right">
                  <img
                     src="assets/images/technology/plug.png"
                    alt="Datavillage"
                    className="technology-picture"
                  />
                </div>
              </Col>
            </Row>
            <Row className="vertical-content ">
              <Col lg={6}><br/><br/><br/>
                <div className="features-img features-right text-right">
                  <img
                    src="assets/images/technology/protect.png"
                    alt="Datavillage"
                    className="technology-picture"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="features-box">
                <p className="text-aqua">
                PROTECT
                  </p>
                  <h3 className="text-white">
                  Define data protection policies that will be enforced when processing data, such as sandboxing, granular consent, secure and trusted execution, encrypted query or private set intersection.
                  <br/><br/>
                  Configure settings for these policies and apply protections in an easy and automated way.</h3>    
                </div>
              </Col>
            </Row>
            <Row className="vertical-content ">
          
              <Col lg={6}>
                <div className="features-box">
                <p className="text-aqua">
                PROCESS
                  </p>
                  <h3 className="text-white">
                  Define and publish your algorithm descriptors on the Gateway. Use your own or start from our templates.<br/> <br/>Run these algorithms, train and apply AI/ML models, and connect your MLOps tools easily behind the Gateway. <br/>Data does not need to be copied, live data is directly accessible by the algorithm and processed encrypted in memory.</h3>
                </div>
              </Col>
              <Col lg={6}><br/><br/><br/>
                <div className="features-img features-right text-right">
                  <img
                     src="assets/images/technology/process.png"
                    alt="Datavillage"
                    className="technology-picture"
                  />
                </div>
              </Col>
            </Row>
            
          </Container>
          
        </section>
      </React.Fragment>
    );
  }
}

export default Technologies
;
