import React, { Component } from "react";
import { Container, Row,Col , FormGroup,Label} from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";


class Plan extends Component {
  
  render() {
    
    return (
      <React.Fragment>
        <section className="section bg-light pt-5" id="plans">
          <Container>
            {/* section title */}
            <SectionTitle className="section-title text-center"
              title="Harness the benefits of shared data <br/>without the need for actual data exchange"
              />


            <Row className="mt-3">
            <Col lg={6} >
              <div className="plan-box-3  hover-effect">
                <h2 className="text-center">Confidential <br/>Anaytics</h2>
                <br/>
                <p className="f-20 text-center  ">Confidential querying, benchmarking and analytics on distributed data </p>
               
               <ul className="list-unstyled text-left">
                    <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;Governance &amp; access control
                      </li>
                      <li className="f-18">
                        <i className="mdi mdi-check"></i>&nbsp;&nbsp;Connect distributed data sources 
                      </li>
                      <li className="f-18">
                        <i className="mdi mdi-check"></i>&nbsp;&nbsp;Always encrypted data
                      </li>
                      <li className="f-18">
                        <i className="mdi mdi-check"></i>&nbsp;&nbsp;No centralized data 
                      </li>
                      <li className="f-18">
                        <i className="mdi mdi-check"></i>&nbsp;&nbsp;Custom algorithm
                      </li>
                      <li className="f-18">
                        <i className="mdi mdi-check"></i>&nbsp;&nbsp;Multi-party federated analytics
                      </li>
                      <li>&nbsp;</li>
                  </ul>

                 
              </div>
            </Col>
            <Col lg={6} >
              <div className="plan-box-2  hover-effect">
                <h2 className="text-center">Confidential <br/>AI</h2>
                <br/>
                <p className="f-20 text-center">Confidential training and inferences on protected AI models</p>
                    <ul className="list-unstyled text-left">
                      <li className="f-18">
                            <i className="mdi mdi-check"></i>&nbsp;&nbsp;Governance &amp; access control
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;Confidential training &amp; inferences
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;Encrypted &amp; protected models
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;Training measurement &amp; monitoring
                        </li>
                        <li className="f-18">
                          <i className="mdi mdi-check"></i>&nbsp;&nbsp;Secure Federated learning
                        </li>
                    </ul>

                   
   
              </div>
             </Col>
            
            </Row>
            <br/><br/>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}


export default Plan;