import React, { Component } from "react";
import { Container, Row,Col } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";

class Process extends Component {
  constructor(props) {
    super(props);
   
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="clients">
          <Container>
            {/* section title */}
           <center><h1 className='f-50'><i className='pe-7s-angle-down-circle'></i></h1></center> 
           
            <Row className="vertical-content ">
            <Col lg={12}>
                <div className="features-box2">
                <h3><b>Do not rely</b> solely on good <b>development practices</b> or the use of "right libraries" <br/>within applications by developers or data scientists. 
                <br/><br/><b>Rely</b> on an <b>hardened component</b> built and configured<br/>  to guarantee confidentiality and privacy when processing data.</h3>
                </div>
                </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
